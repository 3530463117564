import React from "react"

import Layout from "components/layout"
import SEO from "components/seo"
import ServicesContainer from "components/ServicesContainer"
import Container from "components/Container"
import { BMS, UBS, CTN, CMD, LinkContainer } from "./index"
// import Button from "components/Button"
import { Link } from "gatsby"
import BulletList from "components/BulletList"

// const METHODS_LIST = [
//   "You define the requirements for your project, we provide a quote for the price and timeframe. We also discuss plans for domain registration and hosting your website.",
//   "Once we come to an agreement, a contract is formed and we begin our work.",
//   "As we design your product, you will see the progress after each milestone - from wireframing to the full design.",
//   "After the full design is approved, we begin development.",
//   "Once your project is complete and final approval is given, your site is launched!",
// ]

// const SERVICES_LIST = [
//   "Web Design and Development",
//   "Web Hosting",
//   "SEO Services",
//   "eCommerce",
//   "Content Management System",
//   "Logo Design and Rebranding",
//   "Software Consulting",
// ]

const Services = () => (
  <Layout>
    <SEO title="Services" />

    <Container title="Our Services">
      <LinkContainer>
        {/* <Link
          to="/business-management-software"
          style={{ margin: ".25rem .1rem" }}
        > */}

        <ServicesContainer title="Integrated Business Software System">
          <BulletList list={BMS} />
        </ServicesContainer>

        {/* </Link> */}
        {/* <Link to="/startup-service" style={{ margin: ".25rem .1rem" }}> */}

        <ServicesContainer title="Upgrade Business Software">
          <BulletList list={UBS} />
        </ServicesContainer>

        {/* </Link> */}
        {/* <Link to="/startup-service" style={{ margin: ".25rem .1rem" }}> */}

        <ServicesContainer title="Custom Development">
          <BulletList list={CMD} />
        </ServicesContainer>

        {/* </Link> */}
        {/* <Link to="/startup-service" style={{ margin: ".25rem .1rem" }}> */}

        <ServicesContainer title="Google Workspace Solutions">
          <BulletList list={CTN} />
        </ServicesContainer>

        {/* </Link> */}
      </LinkContainer>
    </Container>
  </Layout>
)

export default Services
